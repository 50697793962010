let imgix_url = '';
let shop_url = '';
let privacy_link = '';

function getImgixURL(app_name) {
    if (app_name === 'DIABLO') {
        imgix_url = 'https://diablochairs.imgix.net/';
    } else if (app_name === 'JAN') {
        imgix_url = 'https://jannowak.imgix.net/';
    }

    return imgix_url;
}

function changeColor(variable, color) {
    document.documentElement.style.setProperty(variable, color);
}

function getPrivacy(locale) {
    if (locale === 'cs') {
        privacy_link = 'ochrana-osobnich-udaju';
    } else if (locale === 'de') {
        privacy_link = 'datenschutzerklarung';
    } else if (locale === 'en') {
        privacy_link = 'privacy-policy';
    } else if (locale === 'fr') {
        privacy_link = 'index.php?route=information/information&information_id=145';
    } else if (locale === 'hu') {
        privacy_link = 'adatvedelem';
    } else if (locale === 'ro') {
        privacy_link = 'politica-de-confidentialitate';
    } else if (locale === 'sk') {
        privacy_link = 'ochrana-osobnych-udajov';
    } else {
        privacy_link = 'polityka-prywatnosci';
    }

    return privacy_link;
}

function getLink(locale) {
    if (locale === 'cs') {
        shop_url = '.cz/';
    } else if (locale === 'de') {
        shop_url = '.de/';
    } else if (locale === 'en') {
        shop_url = '.eu/';
    } else if (locale === 'fr') {
        shop_url = '.fr/';
    } else if (locale === 'hu') {
        shop_url = '.hu/';
    } else if (locale === 'ro') {
        shop_url = '.ro/';
    } else if (locale === 'sk') {
        shop_url = '.sk/';
    } else {
        shop_url = '.com/';
    }

    return shop_url;
}

export { getImgixURL, changeColor, getLink, getPrivacy }