<template>
  <div>
    <div class="header">

      <transition name="fade" mode="out-in">
        <div class="cookie-banner" v-if="cookieBanner">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-12">
                <h3>{{ $t('header.text1') }}</h3>
                <p>{{ $t('header.text2') }}</p>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="cookies-btns">
                  <a :href="shop_url + link + privacy" target="_blank" class="more-btn" type="button">{{ $t('header.text3') }}</a>
                  <button class="close-btn" type="button" @click="toggleCookie()">{{ $t('header.text4') }} <img src="/img/icons/close.svg" :alt="$t('header.text4')"/></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <img src="/img/icons/logo-diablo.svg" alt="logo" class="logo" v-if="config_app === 'DIABLO'"/>
            <img src="/img/icons/logo-jannowak.svg" alt="logo" class="logo" v-else-if="config_app === 'JAN'"/>
          </div>
        </div>
      </div>

    </div>
    <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">

          <h3 class="hr-with-text" v-if="order_id">
            <span>{{ $t('home.text1') }} <span class="quantity">#{{ order_id }}</span></span>
          </h3>

          <div class="review-block" v-if="!send">
            <div class="products-content" v-if="products.length">

              <div class="product-review-block" v-for="(product, index) in products" :key="index">

                <div class="left-side">
                  <a :href="shop_url + link + product.canonical_url" target="_blank">
                    <img :src="imgixURL + product.photos[1][0].original" alt="product" class="product-img"/>
                  </a>
                  <p class="price">{{ roundPrice(product.prices.brutto[1].PLN.price) }} zł</p>
                  <a class="title" :href="shop_url + link + product.canonical_url" target="_blank">{{ product.name }}</a>
                </div>

                <div class="right-side">
                  <p class="head">{{ $t('home.text2') }}</p>
                  <star-rating v-model="ratings[index].rating" :active-color="main_color" v-bind:star-size="30"></star-rating>
                  <textarea v-model="ratings[index].text" :placeholder="$t('home.text3')"></textarea>
                </div>
              </div>

              <button class="accept-review-btn main-btn red" @click="review()">{{ $t('home.text4') }}</button>

              <p class="error mt-4" v-if="error_no_rating">{{ error_no_rating }}</p>
              <p class="error mt-4" v-if="error_connect">{{ $t('error.text5') }}</p>

            </div>

            <div class="empty-block" v-else>
              <p>{{ $t('error.text4') }} <span class="error" v-if="error_order">{{ error_order }}</span></p>
            </div>
          </div>

          <div v-else class="thanks-for-review">
            <p>{{ $t('home.text6') }}</p>
          </div>

        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import {getImgixURL, getLink, getPrivacy} from "../utils/mixins";
import { config } from "../utils/config";
import i18n from "../i18n";

export default {
    name: 'Home',
    data() {
     return {
       privacy: '',
       config_app: config.app,
       cookieBanner: true,

       send: false,
       error_order: '',
       order_id: this.getRouteOrderId(),
       email: this.getRouteEmail(),

       error_no_rating: '',
       error_connect: false,

       name: '',
       products: [],
       ratings: [],

       link: '',
       shop_url: '',

       imgixURL: getImgixURL(config.app),

       main_color: document.documentElement.style.getPropertyValue('--main-color')
     }
    },
    mounted: function () {
      this.setLanguage();
      this.getRouteOrderId();
      this.getRouteEmail();
      this.getProducts();

      if(!this.cookieBanner) {
        this.cookieBanner = false;
      }
    },
    components: {
      StarRating
    },
    methods: {
      roundPrice(price) {
        return Number(price).toFixed(2);
      },
      setLanguage() {
        if (this.$route.params.lang !== undefined && this.$route.params.lang !== '') {
          i18n.locale = this.$route.params.lang;
        }

        if (config.app === 'DIABLO') {
          this.shop_url = 'https://diablochairs';
        } else if (config.app === 'JAN') {
          this.shop_url = 'https://jannowak'
        }

        this.link = getLink(i18n.locale);
        this.privacy = getPrivacy(i18n.locale);
      },
      getRouteOrderId() {
        return this.$route.params.order_id;
      },
      getRouteEmail() {
        return this.$route.params.email;
      },
      setRating: function(rating){
        this.rating= rating;
      },
      toggleCookie() {
        this.cookieBanner = !this.cookieBanner;
      },
      getProducts() {
        this.$http.get(this.shop_url + this.link + 'index.php?route=checkout/checkout/get_order_id_email', {
          params: {
            'order_id': this.order_id,
            'email': this.email
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.data.error == 'not_exist') {
            this.error_order = this.$t('error.text1')
          } else if (res.data.error == 'data_not_found') {
            this.error_order = this.$t('error.text2')
          } else if (res.data.error == 'review_exists') {
            this.error_order = this.$t('error.text3')
          } else {
            this.products = [];

            this.name = res.data.order[0].firstname;

            res.data.products.forEach((product) => {
              this.products.push(product)

              this.ratings.push({
                product_id: product.product_id,
                name: this.name,
                text: '',
                rating: 0
              });
            });
          }
        })
      },
      review() {
        let reviewCount = 0;

        this.ratings.forEach((value) => {
          if (value.rating !== 0) {
            this.error_no_rating = '';

            reviewCount++;
          } else {
            if (reviewCount === 0) {
              this.error_no_rating = this.$t('error.text6');
            }
          }
        });

        this.ratings.forEach((value) => {
          if (value.rating !== 0) {
            let form = new FormData();

            form.set('product_id', value.product_id)
            form.set('order_id', this.order_id)
            form.set('name', value.name)
            form.set('text', value.text)
            form.set('rating', value.rating)

            this.$http.post(this.shop_url + this.link + '/index.php?route=product/product/write',
                form,
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
            }).then((res) => {
              console.log(res);
              if (res.data.error == 'empty_name') {
                this.error_connect = true;
              } else if (res.data.error == 'rating_bad_value') {
                this.error_connect = true;
              } else if (res.data.error == 'too_short_text') {
                this.error_connect = true;
              } else if (res.data.success) {
                this.error_connect = false;
                this.send = true;
              }
            });
          }
        });
      }
    }
  }
</script>