<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "./partials/Footer";

export default {
  name: 'App',
  components: { Footer }
}
</script>

<style lang="scss">
@import './assets/scss/all';
</style>