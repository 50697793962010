<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="footer-content">
            <a :href="shop_url + link" class="back-btn">{{ $t('footer.text1') }} <span>{{ shop_url + link }}</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from "../utils/config";
import i18n from "../i18n";
import { getLink } from "../utils/mixins";

export default {
  name: 'Footer',
  data() {
    return {
      link: '',
      shop_url: '',
    }
  },
  mounted() {
    this.setLinks();
  },
  methods: {
    setLinks() {
      if (config.app === 'DIABLO') {
        this.shop_url = 'https://diablochairs';
      } else if (config.app === 'JAN') {
        this.shop_url = 'https://jannowak'
      }

      this.link = getLink(i18n.locale);
    }
  }
}
</script>