<template>
  <div>
    <div class="header">

      <transition name="fade" mode="out-in">
        <div class="cookie-banner" v-if="cookieBanner">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-12">
                <h3>{{ $t('header.text1') }}</h3>
                <p>{{ $t('header.text2') }}</p>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="cookies-btns">
                  <a :href="shop_url + link + privacy" target="_blank" class="more-btn" type="button">{{ $t('header.text3') }} {{ locale }}</a>
                  <button class="close-btn" type="button" @click="toggleCookie()">{{ $t('header.text4') }} <img src="/img/icons/close.svg" :alt="$t('header.text4')"/></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <img src="/img/icons/logo-diablo.svg" alt="logo" class="logo" v-if="config_app === 'DIABLO'"/>
            <img src="/img/icons/logo-jannowak.svg" alt="logo" class="logo" v-else-if="config_app === 'JAN'"/>
          </div>
        </div>
      </div>

    </div>
    <div class="notfound">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="center-notfound">
              <div class="notfound-box">
                <p class="text">{{ $t('not_found.text1') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLink, getPrivacy } from "../utils/mixins";
import { config } from "../utils/config";
import i18n from "../i18n";

export default {
  name: 'Home',
  data() {
    return {
      privacy: '',
      config_app: config.app,
      cookieBanner: true,
      link: '',
      shop_url: '',
    }
  },
  mounted: function () {
    this.setLanguage();

    if(!this.cookieBanner) {
      this.cookieBanner = false;
    }
  },
  methods: {
    setLanguage() {
      if (config.app === 'DIABLO') {
        this.shop_url = 'https://diablochairs';
      } else if (config.app === 'JAN') {
        this.shop_url = 'https://jannowak'
      }

      this.link = getLink(i18n.locale);
      this.privacy = getPrivacy(i18n.locale);
    },
    toggleCookie() {
      this.cookieBanner = !this.cookieBanner;
    }
  }
}
</script>