import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";

import { config } from "../utils/config";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes
});

if (config.app === 'DIABLO') {
    document.title = 'DiabloChairs Review System';
} else if (config.app === 'JAN') {
    document.title = 'JanNowak Review System';
}

export default router;