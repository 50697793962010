import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import axios from "axios"
import VueAxios from "vue-axios"
import { BootstrapVue } from 'bootstrap-vue'

import i18n from './i18n'
import { config } from "./utils/config";
import { changeColor } from './utils/mixins';

if (config.app === 'DIABLO') {
  changeColor("--main-color", "#CB0000");
} else if (config.app === 'JAN') {
  changeColor("--main-color", "#ffd314");
}

const axiosCustom = axios.create();

Vue.use(BootstrapVue)
Vue.use(VueAxios, axiosCustom);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
