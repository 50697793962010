import Home from "../components/Home";
import NotFound from "../components/NotFound";

const routes = [
    {
        path: '/:email/:order_id/:lang?',
        name: 'Home',
        component: Home
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'NotFound',
        component: NotFound
    }
];

export default routes;